import { default as _91id_93YaPly3kiHtMeta } from "/opt/buildhome/repo/pages/dashboard/deployment/[id].vue?macro=true";
import { default as deploymentsJaAO2iX4jHMeta } from "/opt/buildhome/repo/pages/dashboard/deployments.vue?macro=true";
import { default as encrypted_45filesX6WgCcm7RNMeta } from "/opt/buildhome/repo/pages/dashboard/encrypted-files.vue?macro=true";
import { default as networkTolFK802xKMeta } from "/opt/buildhome/repo/pages/dashboard/network.vue?macro=true";
import { default as _91id_93wBgJZ2yUoRMeta } from "/opt/buildhome/repo/pages/dashboard/template/[id].vue?macro=true";
import { default as templatesHpKdN2Ho1TMeta } from "/opt/buildhome/repo/pages/dashboard/templates.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as indexGjvb4dkXLuMeta } from "/opt/buildhome/repo/pages/settings/index.vue?macro=true";
import { default as membersRjIRD2wi8eMeta } from "/opt/buildhome/repo/pages/settings/members.vue?macro=true";
import { default as notificationsesR0fmPZzDMeta } from "/opt/buildhome/repo/pages/settings/notifications.vue?macro=true";
export default [
  {
    name: _91id_93YaPly3kiHtMeta?.name ?? "dashboard-deployment-id",
    path: _91id_93YaPly3kiHtMeta?.path ?? "/dashboard/deployment/:id()",
    meta: _91id_93YaPly3kiHtMeta || {},
    alias: _91id_93YaPly3kiHtMeta?.alias || [],
    redirect: _91id_93YaPly3kiHtMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/deployment/[id].vue").then(m => m.default || m)
  },
  {
    name: deploymentsJaAO2iX4jHMeta?.name ?? "dashboard-deployments",
    path: deploymentsJaAO2iX4jHMeta?.path ?? "/dashboard/deployments",
    meta: deploymentsJaAO2iX4jHMeta || {},
    alias: deploymentsJaAO2iX4jHMeta?.alias || [],
    redirect: deploymentsJaAO2iX4jHMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/deployments.vue").then(m => m.default || m)
  },
  {
    name: encrypted_45filesX6WgCcm7RNMeta?.name ?? "dashboard-encrypted-files",
    path: encrypted_45filesX6WgCcm7RNMeta?.path ?? "/dashboard/encrypted-files",
    meta: encrypted_45filesX6WgCcm7RNMeta || {},
    alias: encrypted_45filesX6WgCcm7RNMeta?.alias || [],
    redirect: encrypted_45filesX6WgCcm7RNMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/encrypted-files.vue").then(m => m.default || m)
  },
  {
    name: networkTolFK802xKMeta?.name ?? "dashboard-network",
    path: networkTolFK802xKMeta?.path ?? "/dashboard/network",
    meta: networkTolFK802xKMeta || {},
    alias: networkTolFK802xKMeta?.alias || [],
    redirect: networkTolFK802xKMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/network.vue").then(m => m.default || m)
  },
  {
    name: _91id_93wBgJZ2yUoRMeta?.name ?? "dashboard-template-id",
    path: _91id_93wBgJZ2yUoRMeta?.path ?? "/dashboard/template/:id()",
    meta: _91id_93wBgJZ2yUoRMeta || {},
    alias: _91id_93wBgJZ2yUoRMeta?.alias || [],
    redirect: _91id_93wBgJZ2yUoRMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/template/[id].vue").then(m => m.default || m)
  },
  {
    name: templatesHpKdN2Ho1TMeta?.name ?? "dashboard-templates",
    path: templatesHpKdN2Ho1TMeta?.path ?? "/dashboard/templates",
    meta: templatesHpKdN2Ho1TMeta || {},
    alias: templatesHpKdN2Ho1TMeta?.alias || [],
    redirect: templatesHpKdN2Ho1TMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/templates.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexGjvb4dkXLuMeta?.name ?? "settings",
    path: indexGjvb4dkXLuMeta?.path ?? "/settings",
    meta: indexGjvb4dkXLuMeta || {},
    alias: indexGjvb4dkXLuMeta?.alias || [],
    redirect: indexGjvb4dkXLuMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: membersRjIRD2wi8eMeta?.name ?? "settings-members",
    path: membersRjIRD2wi8eMeta?.path ?? "/settings/members",
    meta: membersRjIRD2wi8eMeta || {},
    alias: membersRjIRD2wi8eMeta?.alias || [],
    redirect: membersRjIRD2wi8eMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings/members.vue").then(m => m.default || m)
  },
  {
    name: notificationsesR0fmPZzDMeta?.name ?? "settings-notifications",
    path: notificationsesR0fmPZzDMeta?.path ?? "/settings/notifications",
    meta: notificationsesR0fmPZzDMeta || {},
    alias: notificationsesR0fmPZzDMeta?.alias || [],
    redirect: notificationsesR0fmPZzDMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings/notifications.vue").then(m => m.default || m)
  }
]