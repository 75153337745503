import revive_payload_client_O8TuyXkIkq from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.3_eslint@8.56.0_typescript@5.3.3_vite@5.1.4_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_nYHSPYO0Ba from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.3_eslint@8.56.0_typescript@5.3.3_vite@5.1.4_vue-tsc@1.8.27/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_OYXaRMCzqV from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.3_eslint@8.56.0_typescript@5.3.3_vite@5.1.4_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_MnJOy5kH8u from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.3_eslint@8.56.0_typescript@5.3.3_vite@5.1.4_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_f8M7XjNBMU from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.3_eslint@8.56.0_typescript@5.3.3_vite@5.1.4_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_K8DUkfBiHJ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.3_eslint@8.56.0_typescript@5.3.3_vite@5.1.4_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import modals_Pe74QYmfhE from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.14.0_nuxt@3.10.3_vite@5.1.4_vue@3.4.19/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_YxUqeNkg9X from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.14.0_nuxt@3.10.3_vite@5.1.4_vue@3.4.19/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_3dFO4wL2SD from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.3.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_MYjALgrtBl from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.3_eslint@8.56.0_typescript@5.3.3_vite@5.1.4_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import presets_KnlWTsWP8D from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.0.0_nuxt@3.10.3_vite@5.1.4_vue@3.4.19/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import scrollbars_client_8UdU8FeTk8 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.0.0_nuxt@3.10.3_vite@5.1.4_vue@3.4.19/node_modules/@nuxt/ui-pro/plugins/scrollbars.client.ts";
import variables_JlDtPWblq0 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.0.0_nuxt@3.10.3_vite@5.1.4_vue@3.4.19/node_modules/@nuxt/ui-pro/plugins/variables.ts";
export default [
  revive_payload_client_O8TuyXkIkq,
  unhead_nYHSPYO0Ba,
  router_OYXaRMCzqV,
  payload_client_MnJOy5kH8u,
  check_outdated_build_client_f8M7XjNBMU,
  components_plugin_KR1HBZs4kY,
  prefetch_client_K8DUkfBiHJ,
  modals_Pe74QYmfhE,
  colors_YxUqeNkg9X,
  plugin_client_3dFO4wL2SD,
  chunk_reload_client_MYjALgrtBl,
  presets_KnlWTsWP8D,
  scrollbars_client_8UdU8FeTk8,
  variables_JlDtPWblq0
]