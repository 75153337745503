import routerOptions0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.3_eslint@8.56.0_typescript@5.3.3_vite@5.1.4_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.0.0_nuxt@3.10.3_vite@5.1.4_vue@3.4.19/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}